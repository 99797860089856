import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Timeline.css";

const VTEProps = {
  className: "vertical-timeline-element--work",
  contentStyle: {
    color: "#071428",
    boxShadow: "none",
    textAlign: "left",
    padding: "1rem",
  },
  contentArrowStyle: {
    display: "none",
  },

  dateClassName: "milestone",
  iconStyle: {
    background: "#fff",
    color: "#071428",
    fontSize: "1.5rem",
    fontWeight: 400,
    border: "1px solid #071428",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 0 20px #00000050, inset 0 0 5px #00000050",
  },
  position: "right",
};

export default function Timeline() {
  return (
    <VerticalTimeline layout="2-columns">
      <VerticalTimelineElement {...VTEProps} date="Testnet" icon={<p>1.0</p>}>
        {/* <h3 className="vertical-timeline-element-title">Creative Director</h3>
        <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
        <p>
          • No Loss Prize Games
          <br />
          <br />
          • Staking
          <br />
          <br />• Smart Contract Audit
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement {...VTEProps} date="Mainnet" icon={<p>2.0</p>}>
        <p>
          • No Loss Prize Game NFT Draws 
          <br />
          <br />
          • Bridge Integration
          <br />
          <br />
          • VRF Winner Selection
          <br />
          <br />
          • Auto Compounding Staking Rewards
          <br />
          <br />
          • Batch Unstaking Mechanism
          <br />
          <br />
          • Liquidity Pools
          <br />
          <br />• Smart Contract Audit
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement {...VTEProps} date="Onwards!" icon={<p>3.0</p>}>
        <p>
          • SEN Token IDO
          <br />
          <br />
          • Permissionless Staking Pool Creation
          <br />
          <br />
          • Exchange Listings
          <br />
          <br />
          • Partnering With Cross Chain DeFi
          <br />
          <br />
          • Yield Aggregator
          <br />
          <br />• DEX Router & Aggregator Integration
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
}
